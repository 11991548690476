//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { SearchService } from "../../../services/search/search.service";
import { S25Const } from "../../../util/s25-const";
import { Api } from "../../../api/api";
import { DropdownApi } from "../dropdown.api";
import { UserprefService } from "../../../services/userpref.service";
import { Item } from "../../../pojo/Item";

export class SearchDropdownApi extends Api {
    static refresh(relativeElem: HTMLElement, items: any[]) {
        return SearchDropdownApi.callApiFn(relativeElem, "s25-ng-search-dropdown", "refresh", items);
    }

    static reloadSearches(relativeElem: HTMLElement, data: any) {
        return SearchDropdownApi.callApiFn(relativeElem, "s25-ng-search-dropdown", "reloadSearches", data);
    }

    static selectSearch(relativeElem: HTMLElement, data: any) {
        return SearchDropdownApi.callApiFn(relativeElem, "s25-ng-search-dropdown", "selectSearch", data);
    }
}

@TypeManagerDecorator("s25-ng-search-dropdown")
@Component({
    selector: "s25-ng-search-dropdown",
    template: `
        <ng-container *ngIf="this.items">
            <s25-generic-dropdown
                [(chosen)]="this.chosen"
                [hasFav]="this.hasFav"
                [onSelect]="this.onSelect"
                [items]="this.items"
                [staticItems]="this.staticItems"
                [autoOpen]="this.autoOpen"
                [alwaysOpen]="this.alwaysOpen"
                [hideSearch]="this.hideSearch"
                [focusFirst]="false"
                [searchEnabled]="this.searchEnabled"
                [apiBean]="this.apiBean"
                [serverSide]="this.serverSide"
                [placeholder]="this.placeholder"
                [resetSelectedOnCleanup]="this.resetSelectedOnCleanup"
                (chosenChange)="onChosenChange($event)"
            >
            </s25-generic-dropdown>
        </ng-container>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25SearchDropdownComponent extends S25DropdownAbstract implements OnInit, OnChanges {
    @Input() type?: Item.Subject;
    @Input() itemTypeId?: number;
    @Input() allowNonQueryId: boolean;
    @Input() additionalSearches: any[];
    @Input() placeholder: string;
    @Input() initialSearch: any; // Argument to selectSearch
    @Input() onlyPublic: boolean = false;

    initSearchPromise: Promise<any>;

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        super();
        this.elementRef.nativeElement.angBridge = this;
    }

    getSearches = (isReload?: boolean): Promise<any[]> => {
        let method = isReload ? SearchService.reloadStrictSearches : SearchService.getStrictSearches;
        return method(this.itemTypeId, this.allowNonQueryId).then((data) => {
            let searches = data?.opt || [];
            if (this.onlyPublic) searches = searches.filter((search: any) => search.grp === "Public Searches");
            return searches.concat(this.additionalSearches);
        });
    };

    reloadSearches = (data: any) => {
        if (data && (data.itemTypeId === this.itemTypeId || data.itemTypeId - 100 === this.itemTypeId)) {
            let isFullReload = S25Util.coalesce(data.isFullReload, true);
            return this.getSearches(isFullReload).then((searches) => {
                this.refresh(searches);
                this.selectSearch(data); //allow API callers to specify a new chosen search inside this reload request
            });
        }
    };

    selectSearch = (data: any) => {
        if (
            data &&
            data.property &&
            data.value &&
            (data.itemTypeId === this.itemTypeId || data.itemTypeId - 100 === this.itemTypeId)
        ) {
            this.initSearchPromise &&
                this.initSearchPromise.then(() => {
                    //wait for init if search selection request comes in while still going (has no effect if already init)
                    this.zone.run(() => {
                        let newModel = S25Util.propertyGetParentWithChildValue(this.items, data.property, data.value);
                        if (newModel) {
                            this.chosen = newModel;
                            this.chosenChange.emit(this.chosen);
                            try {
                                this.cd.detectChanges();
                            } catch (error: any) {}
                            this.zone.run(() => {
                                this.onSelect && this.onSelect(this.chosen);
                                this.zone.run(() => {
                                    data.callback && data.callback();
                                    try {
                                        this.cd.detectChanges();
                                    } catch (error: any) {}
                                });
                            });
                        }
                    });
                });
        }
    };

    refresh = (items: any[]) => {
        this.items = items;
        DropdownApi.refresh(this.elementRef.nativeElement, this.items);
        try {
            this.cd.detectChanges();
        } catch (error: any) {}
    };

    chosenSetter = (newVal: any) => {
        if (!newVal) {
            this.chosen = null;
        } else {
            this.chosen = this.items.filter((item) => {
                return item && item.val == newVal;
            })[0];
        }
        try {
            this.cd.detectChanges();
        } catch (error: any) {}
    };

    ngOnInit() {
        this.itemTypeId ??= S25Const.itemName2Id[this.type];
        this.allowNonQueryId = S25Util.toBool(S25Util.coalesce(this.allowNonQueryId, true));
        this.additionalSearches = this.additionalSearches || [];
        this.searchEnabled = true;
        this.placeholder = this.placeholder || "Select a search";

        this.initSearchPromise = S25Util.all({
            isLoggedIn: UserprefService.getLoggedIn(),
            items: this.getSearches(),
        }).then((resp) => {
            this.hasFav = resp.isLoggedIn;
            this.items ??= resp.items; // Race condition. Only set default items if no other component set items already
            if (this.initialSearch) this.selectSearch(this.initialSearch);
            this.onLoad && this.onLoad();
            try {
                this.cd.detectChanges();
            } catch (error: any) {}
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.itemTypeId && !changes.itemTypeId.isFirstChange()) {
            this.reloadSearches({ itemTypeId: this.itemTypeId });
        }
    }
}
