//@author: kate

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
    ChangeDetectorRef,
    ViewRef,
} from "@angular/core";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { SearchCriteriaService } from "../../../services/search/search-criteria/search.criteria.service";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-event-role-dropdown")
@Component({
    selector: "s25-event-role-dropdown",
    template: `
        <ng-container *ngIf="this.items">
            <s25-generic-dropdown
                [items]="this.items"
                [(chosen)]="this.chosen"
                [onSelect]="this.onSelect"
                [autoOpen]="this.autoOpen"
                [focusFirst]="this.focusFirst"
                [alwaysOpen]="this.alwaysOpen"
                [hideSearch]="this.hideSearch"
                [apiBean]="this.apiBean"
                [placeholder]="'Search roles'"
                (chosenChange)="onChosenChange($event)"
            ></s25-generic-dropdown>
        </ng-container>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25EventRoleDropdownComponent extends S25DropdownAbstract implements OnInit {
    @Input() excludeIds: number[];

    constructor(
        private zone: NgZone,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit() {
        SearchCriteriaService.getEventRoles(true, true).then((data: DropDownItem[]) => {
            this.zone.run(() => {
                if (this.excludeIds && this.excludeIds.length) {
                    //should it be this instead?: this.excludeIds = this.excludeIds || [];
                    // data = data.filter(function (item: any) {
                    //     return this.excludeIds.indexOf(item.itemId) === -1;
                    // });
                    data = data.filter((item: any) => !this.excludeIds.includes(item.itemId));
                }

                this.items = data;
                this.onLoad && this.onLoad();
                this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
            });
        });
    }
}
