//@author: travis
import { ChangeDetectionStrategy, Component, Input, NgZone, OnInit, ViewEncapsulation } from "@angular/core";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { SearchCriteriaService } from "../../../services/search/search-criteria/search.criteria.service";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-category-dropdown")
@Component({
    selector: "s25-category-dropdown",
    template: `
        <ng-container *ngIf="this.items">
            <s25-generic-dropdown
                [chosen]="this.chosen"
                [hasFav]="false"
                [onSelect]="this.onSelect"
                [items]="this.items"
                [staticItems]="this.staticItems"
                [autoOpen]="this.autoOpen"
                [alwaysOpen]="this.alwaysOpen"
                [hideSearch]="this.hideSearch"
                [focusFirst]="true"
                [searchEnabled]="true"
                [apiBean]="this.apiBean"
                [serverSide]="this.serverSide"
                [placeholder]="'Select a Category'"
                [resetSelectedOnCleanup]="this.resetSelectedOnCleanup"
            >
            </s25-generic-dropdown>
        </ng-container>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25CategoryDropdownComponent extends S25DropdownAbstract implements OnInit {
    @Input() itemTypeId: number;

    constructor(private zone: NgZone) {
        super();
    }

    ngOnInit() {
        if (this.chosen) {
            this.chosen.itemTypeId = this.itemTypeId;
        }
        SearchCriteriaService.getObjectCategories(this.itemTypeId).then((data) => {
            this.zone.run(() => {
                this.items = data;
                this.onLoad && this.onLoad();
            });
        });
    }
}
