//@author: devin
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { TaxesService } from "../../../services/taxes.service";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-tax-schedules-dropdown")
@Component({
    selector: "s25-ng-tax-schedules-dropdown",
    template: `
        <ng-container *ngIf="this.items">
            <s25-generic-dropdown
                [items]="this.items"
                [(chosen)]="this.chosen"
                [onSelect]="this.onSelect"
                [searchEnabled]="true"
                [hasFav]="false"
                [staticItems]="this.staticItems"
                [autoOpen]="this.autoOpen"
                [focusFirst]="this.focusFirst"
                [alwaysOpen]="this.alwaysOpen"
                [hideSearch]="this.hideSearch"
                [apiBean]="this.apiBean"
                [resetSelectedOnCleanup]="this.resetSelectedOnCleanup"
                (chosenChange)="onChosenChange($event)"
            >
                ></s25-generic-dropdown
            >
        </ng-container>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25TaxSchedulesDropdownComponent extends S25DropdownAbstract implements OnInit {
    constructor(
        private zone: NgZone,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit() {
        TaxesService.getTaxes().then((data: any) => {
            this.zone.run(() => {
                this.items = data.map((obj: any) => {
                    return { itemName: obj.tax_name, itemId: obj.tax_id };
                });
                this.onLoad && this.onLoad();
                this.cd.detectChanges();
            });
        });
    }
}
