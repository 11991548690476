//@author: travis
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
    ChangeDetectorRef,
} from "@angular/core";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { SearchCriteriaService } from "../../../services/search/search-criteria/search.criteria.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { AttributeI } from "../../../pojo/AttributeI";

@TypeManagerDecorator("s25-custom-attr-dropdown")
@Component({
    selector: "s25-custom-attr-dropdown",
    template: `
        <ng-container *ngIf="this.items">
            <s25-generic-dropdown
                [(chosen)]="this.chosen"
                [hasFav]="this.hasFav"
                [onSelect]="this.onSelect"
                [items]="this.items"
                [staticItems]="this.staticItems"
                [autoOpen]="this.autoOpen"
                [alwaysOpen]="this.alwaysOpen"
                [hideSearch]="this.hideSearch"
                [focusFirst]="false"
                [searchEnabled]="true"
                [apiBean]="this.apiBean"
                [serverSide]="this.serverSide"
                [placeholder]="'Select Custom Attribute'"
                (chosenChange)="onChosenChange($event)"
                [resetSelectedOnCleanup]="this.resetSelectedOnCleanup"
            >
            </s25-generic-dropdown>
        </ng-container>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25CustomAttrDropdownComponent extends S25DropdownAbstract implements OnInit {
    @Input() itemTypeId: number;
    @Input() onEventForm?: boolean = false;
    @Input() useSecurity?: boolean = false;
    @Input() eventTypeId?: number;
    @Input() whitelistedTypes: string[];

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit() {
        this.refresh();
    }

    refresh(inputs?: any) {
        //data can have eventTypeId selected from event details event type dropdown component
        if (inputs) {
            S25Util.extend(this, inputs);
        }
        //event type id either provided via refresh extend (event details when event type changed) OR via dropdown creation (event details initial load, and event form creation initial load / refresh)
        //note: we add 10 bc itemTypeId of 1 + 10 = 11, which tells the service to use ev_cust_atrb_wizard instead of ev_cust_atrb_details to filter cust attrs
        SearchCriteriaService.getCustomAttributeItems(
            this.itemTypeId + (this.onEventForm ? 10 : 0),
            S25Util.toBool(this.useSecurity),
            this.eventTypeId,
        ).then((data: AttributeI[]) => {
            data = data || [];

            if (this.whitelistedTypes && this.whitelistedTypes.length) {
                data = data.filter((obj) => {
                    return this.whitelistedTypes.indexOf(S25Util.toStr(obj.itemTypeId)) > -1;
                });
            }

            this.items = data.map((obj) => {
                return {
                    txt: obj.itemName,
                    val: obj.itemId,
                    custAtrbType: obj.itemTypeId,
                    sort_order: obj.sort_order,
                    multi_val: obj?.multi_val,
                };
            });

            this.onLoad && this.onLoad();

            try {
                this.cd.detectChanges();
            } catch (error: any) {}
        });
    }
}
