//@author: devin
import { ChangeDetectionStrategy, Component, Input, NgZone, OnInit, ViewEncapsulation } from "@angular/core";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { SearchCriteriaService } from "../../../services/search/search-criteria/search.criteria.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";

@TypeManagerDecorator("s25-event-type-dropdown")
@Component({
    selector: "s25-event-type-dropdown",
    template: `
        <ng-container *ngIf="this.items">
            <s25-generic-dropdown
                [items]="this.items"
                [chosen]="this.chosen"
                [onSelect]="this.onSelect"
                [searchEnabled]="true"
                [hasFav]="true"
                [staticItems]="this.staticItems"
                [autoOpen]="this.autoOpen"
                [focusFirst]="this.focusFirst"
                [alwaysOpen]="this.alwaysOpen"
                [hideSearch]="this.hideSearch"
                [apiBean]="this.apiBean"
                [resetSelectedOnCleanup]="this.resetSelectedOnCleanup"
            ></s25-generic-dropdown>
        </ng-container>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25EventTypeDropdownComponent extends S25DropdownAbstract implements OnInit {
    @Input() formTypesOnly: boolean;
    constructor(private zone: NgZone) {
        super();
    }

    ngOnInit() {
        if (this.chosen) {
            this.chosen.itemTypeId = 7;
        }
        //if useSecurity is set, list of event types is filtered to those allowed by rose config and preference config (used for writing new event type)
        SearchCriteriaService.getEventTypes(
            S25Util.toBool(this.useSecurity),
            S25Util.toBool(this.activeOnly),
            S25Util.toBool(this.formTypesOnly),
        ).then((data: DropDownItem[]) => {
            this.zone.run(() => {
                this.staticItems = data.filter((obj: DropDownItem) => {
                    return obj.favorite === "T";
                });
                this.items = data;
                this.onLoad && this.onLoad();
            });
        });
    }
}
