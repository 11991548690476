//@author: devin
import { Component, ElementRef, Input, NgZone, OnInit } from "@angular/core";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { SearchCriteriaService } from "../../../services/search/search-criteria/search.criteria.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { DropdownDataUtil } from "../dropdown.data.util";

@TypeManagerDecorator("s25-location-dropdown")
@Component({
    selector: "s25-location-dropdown",
    template: `
        <ng-container *ngIf="this.items">
            <s25-generic-dropdown
                [chosen]="this.chosen"
                [hasFav]="true"
                [onSelect]="this.onSelect"
                [items]="this.items"
                [staticItems]="this.staticItems"
                [autoOpen]="this.autoOpen"
                [alwaysOpen]="this.alwaysOpen"
                [hideSearch]="this.hideSearch"
                [focusFirst]="true"
                [searchEnabled]="true"
                [apiBean]="this.apiBean"
                [serverSide]="this.serverSide"
                [placeholder]="'Search spaces'"
                [resetSelectedOnCleanup]="this.resetSelectedOnCleanup"
            >
            </s25-generic-dropdown>
        </ng-container>
    `,
})
export class S25LocationDropdownComponent extends S25DropdownAbstract implements OnInit {
    @Input() express?: any;

    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
    ) {
        super();
    }

    ngOnInit() {
        if (this.chosen) {
            this.chosen.itemTypeId = 4;
        }
        if (this.express) {
            SearchCriteriaService.getLocations(-999, 0, 999, null, null, null, "&direct=T&min_ols=R&can_assign=T").then(
                (data: any) => {
                    this.zone.run(() => {
                        let items = (data && data.searchCriteria && data.searchCriteria.item) || [];
                        if (items && items.length) {
                            this.express.getAvailableItems(items).then((availItems: any[]) => {
                                let favs = availItems.filter((item: any) => {
                                    return item.favorite === "T";
                                });
                                this.staticItems = S25Util.deepCopy(favs);
                                this.items = S25Util.deepCopy(availItems);
                                this.onLoad && this.onLoad();
                            });
                        } else {
                            this.onLoad && this.onLoad();
                        }
                    });
                },
            );
        } else {
            SearchCriteriaService.getLocations(-999, 0, 999, null, true, null, null).then((data: any) => {
                this.zone.run(() => {
                    this.staticItems = (data && data.searchCriteria && data.searchCriteria.item) || [];
                    this.serverSide = DropdownDataUtil.getServerSideModel(
                        "getLocations",
                        this.elementRef.nativeElement,
                        null,
                    );
                    this.items = S25Util.deepCopy(this.staticItems);
                    this.onLoad && this.onLoad();
                });
            });
        }
    }
}
